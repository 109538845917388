<template>
  <component
    :is="component"
    class="min-w-[20px] h-[--height] inline-flex items-center justify-center font-semibold"
    :class="{
      'px-3 gap-2 text-sm sm:text-base rounded': size !== 'small',
      'gap-1 px-1 text-xs rounded-sm': size === 'small',
      'cursor-pointer': !!$attrs.onClick,
    }"
    :style="{
      '--height': `${size === 'small' ? 22 : 35}px`,
    }"
    v-bind="bind"
    :tabindex="$attrs.onClick ? 0 : undefined"
  >
    <slot name="prepend">
      <app-icon
        v-if="icon"
        :icon="icon"
        :size="size === 'small' ? 16 : undefined"
      />
    </slot>

    <slot />

    <app-icon
      v-if="appendIcon"
      :icon="appendIcon"
      :size="size === 'small' ? 16 : undefined"
    />

    <button
      v-if="deletable"
      class="relative before:absolute before:-inset-1 before:content-['']"
      type="button"
      @click="$emit('delete')"
    >
      <app-icon icon="ph:x" :size="16" />
    </button>
  </component>
</template>

<script lang="ts" setup>
import type { RouteLocationNamedRaw, RouteLocationRaw } from "vue-router";

const NuxtLink = resolveComponent("nuxt-link");

const properties = withDefaults(
  defineProps<{
    color?: string;
    to?: RouteLocationNamedRaw | RouteLocationRaw;
    href?: string;
    size?: "small";
    tag?: string;
    icon?: string;
    appendIcon?: string;
    deletable?: boolean;
  }>(),
  {
    color: undefined,
    to: undefined,
    size: undefined,
    href: undefined,
    tag: "span",
    icon: undefined,
    appendIcon: undefined,
  },
);

defineEmits(["delete"]);

const bind = computed(() => {
  const binding = new Map([]);

  binding.set("style", { backgroundColor: properties.color });

  if (properties.to) {
    binding.set("to", properties.to);
  }

  if (properties.href) {
    binding.set("href", properties.href);
    binding.set("target", "_blank");
  }

  return Object.fromEntries(binding.entries());
});

const component = computed(() => {
  if (properties.to) return NuxtLink;
  if (properties.href) return "a";

  return properties.tag;
});
</script>
